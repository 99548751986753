import React from 'react';

import GDPR, { GDPRContext } from '../../providers/gdpr';
import Links from '../../components/links';
import '../../components/app.scss';


class LinksPage extends React.Component {

    render() {
        return (
            <div className="app">
                <GDPR>
                    <Links />
                </GDPR>
            </div>
        )
    }

}

export default LinksPage;