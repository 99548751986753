import React, { Component } from 'react';
import { StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import heroImg from './../../assets/ABL-logo.svg';
import logo from './../../assets/ABL-logo.svg';
import './hero.scss';

const heroImage = (
    <StaticQuery
        query={graphql`
            query HeroImage {
                file(relativePath: { eq: "brilliant-hero-3.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2880, quality: 75) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
    >
        {data => <Img fluid={data.file.childImageSharp.fluid} className="hero__image" alt="A Brilliant Lie." />}
    </StaticQuery>
)

class Hero extends Component {

    render() {
        return (
            <div className="hero">
                <div className="hero__logo-container">
                    <img src={logo} className="hero__logo" />
                </div>
                {heroImage}
            </div>
        )
    }

}

export default Hero;
