import React, { Component } from 'react'
import Helmet from 'react-helmet';

import config from '../config';
import getGeoLocation from '../actions/get-geo-location';

const GEO_LOCATION_KEY = '_abl-geo-location';

const checkGDPRRequirement = (geoLocation) => {
    return geoLocation !== "US";
}

export const GDPRContext = React.createContext();

const GDPR = ({ children }) => {
    return (
        <GDPRContext.Consumer>
            {context => {
                return context ? children(context) : <GDPRProvider>{children}</GDPRProvider>
            }}
        </GDPRContext.Consumer>
    )
} 

// TODO: Track time of cookie acceptance.
// TODO: Check History Change Triggers in Google Tag Manager.

class GDPRProvider extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            geoLocation: null,
            gdprRequired: false,
            acceptedGDPR: false,
            tracking: {}
        }

        this.fbScriptID = `fb_${config.FACEBOOK_PIXEL}`;
        this.gtagScriptID = `gtag_${config.GOOGLE_ANALYTICS}`;
    }

    setGeoLocation = (geoLocation) => {
        const gdprRequired = checkGDPRRequirement(geoLocation);
        this.setState({ geoLocation, gdprRequired });
    }

    setGDPR = (value) => {
        this.setState({ acceptedGDPR: value });
    }

    componentDidMount() {

        // GEO LOCATION
        const geoLocation = sessionStorage.getItem(GEO_LOCATION_KEY);

        if (geoLocation) {
            this.setGeoLocation(geoLocation);
        } else {
            getGeoLocation()
                .then((result) => {
                    this.setGeoLocation(geoLocation);
                    sessionStorage.setItem(GEO_LOCATION_KEY, result);
                })
        }

    }

    getContext() {
        return {
            ...this.state,
            setGDPR: this.setGDPR
        }                       
    }

    /**
     * @method createTrackingFunction
     * 
     * Creates a new tracking function based on the key provided.  The new function
     * accepts any number of arguments and passes them on to the function available
     * at `window[key]`.  However, the new function will provide additional checks
     * prior to executing the window function, if will test to make sure GDPR is
     * either accepted or not required.  
     * 
     * @param key {string} - name of function on window object dedicated to tracking object
     * @param args {any} - Variables passed to window[key]
     * @return {(...args: any) => void}
     */
    createTrackingFunction = (key) => (...args) => {
        if (
            typeof window[key] === `function`
            && (this.state.acceptedGDPR || !this.state.gdprRequired) 
            && process.env.NODE_ENV === `production`
        ) {
            window[key](...args);
        }
    }

    setTracking = (_, { scriptTags }) => {
        if (!Array.isArray(scriptTags)) {
            // Do nothing.
            return;
        }

        const resourceIDs = scriptTags.map((tag) => tag.id);

        if (
            resourceIDs.includes(this.fbScriptID) 
            && !this.state.tracking.fbq
            && window.fbq
        ) {
            const fn = this.createTrackingFunction('fbq');
            this.setState({ tracking: { ...this.state.tracking, fbq: fn } });
        }

        if (
            resourceIDs.includes(this.gtagScriptID) 
            && !this.state.tracking.gtag
            && window.gtag
        ) {
            const fn = this.createTrackingFunction('gtag');
            this.setState({ tracking: { ...this.state.tracking, gtag: fn } });
        }
    }

    render() {
        const { children } = this.props;
        const { gdprRequired, acceptedGDPR } = this.state;
        const context = this.getContext();

        const younglings = React.Children.map(children, child => {
            return React.cloneElement(child, {
                ...this.props,
                ...context
            })
        })

        return (
            <React.Fragment>

                {
                    (acceptedGDPR || !gdprRequired) 
                    && process.env.NODE_ENV === `production`
                    && <Helmet onChangeClientState={this.setTracking}>

                        {/* FB PIXEL */}
                        <script id={this.fbScriptID}>
                            {`
                                !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
                                n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
                                t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
                                document,'script','https://connect.facebook.net/en_US/fbevents.js');
                                fbq('init', '${config.FACEBOOK_PIXEL}');
                                fbq('track', 'PageView');
                            `}
                        </script>

                        {/* GOOGLE TAG */}
                        <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.GOOGLE_ANALYTICS}`}></script>
                        <script id={this.gtagScriptID}>
                            {`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${config.GOOGLE_ANALYTICS}');
                            `}
                        </script>

                    </Helmet>
                }

                <GDPRContext.Provider value={context}>
                    {younglings}
                </GDPRContext.Provider>
            </React.Fragment>
        );
    }

}

export default GDPR;