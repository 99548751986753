import React, { Component } from 'react';
import qs from 'qs';

import Header from '../components/header/header';
import Hero from '../components/hero/hero';
import Music from '../components/music/music';
import Videos from '../components/videos/videos';
import Tour from '../components/tour/tour';
import News from '../components/news/news';
import Band from '../components/band/band';
import Listen from '../components/listen/listen';
import Media from '../components/media/media';
import ImageContainer from '../components/image/image';
import Contact from '../components/contact/contact';

import heroImage from '../assets/brilliant-hero.jpg';
import tara3 from '../assets/tara3.jpg';
import haven from '../assets/haven.jpg';

import '../components/app.scss';
import '../components/home.scss';

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            unreleased: false
        }

        this.displayUnreleasedTracks = this.displayUnreleasedTracks.bind(this);
    }

    componentDidMount() {
        this.displayUnreleasedTracks();
    }

    displayUnreleasedTracks() {
        const query = this.props.location.search.replace('?', '');
        const parsed = qs.parse(query);
        Object.keys(parsed).forEach((key) => {
            if (key === 'unreleased') {
                this.setState({ unreleased: true})
            }
        });
    }

    render() {

        return (
            <div class="home">
                <Header 
                    { ...this.state }
                    { ...this.props }
                />
                <Hero />
                <Videos 
                    { ...this.state }
                />
                <Band />
                <ImageContainer>
                    <img src={haven} />
                </ImageContainer>
                <Music 
                    { ...this.state }
                />
                <Listen />
                <ImageContainer>
                    <img src={tara3} />
                </ImageContainer>
                <Tour />
                <Media />
                <Contact />
            </div>
        )
    }

}

export default Home;