import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './image.scss';

class ImageContainer extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="image-container">
                {this.props.children}
            </div>
        )
    }

}

export default ImageContainer;