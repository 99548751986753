import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './header.scss';
import logo from './../../assets/ABL-logo.svg';
import pinwheel from './../../assets/pin-wheel.svg';
import videos from '../videos/videos';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            main: process.env.REACT_APP_SITE === "main"
        }

        this.scrollTo = this.scrollTo.bind(this);
    }

    scrollTo(id) {
        let element = document.querySelector(`.${id}`);
        let location = element.getBoundingClientRect().y;
        window.scroll({
            top: location,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        return (
            <div className="header">
                <div className="header__links">
                    <a className="header__link" onClick={() => this.scrollTo('videos')}>VIDEOS</a>
                    <a className="header__link" onClick={() => this.scrollTo('listen')}>MUSIC</a>
                    <a className="header__link" onClick={() => this.scrollTo('music')}>ALBUMS</a>
                    <div className="header__logo-container">
                        <img src={logo} className="header__logo" />
                    </div>
                    <img src={pinwheel} className="header__pinwheel" />
                    <a className="header__link" onClick={() => this.scrollTo('band')}>ABOUT</a>
                    <a className="header__link" onClick={() => this.scrollTo('tour')}>TOUR</a>
                    <a className="header__link header__link--mobile" href= "http://store.abrilliantlie.com" target="_blank">STORE</a>
                    {/* <a className="header__link">FAN</a>
                    <a className="header__link">SHOP</a>
                    <a className="header__link">CONNECT</a> */}
                </div>
            </div>
        )
    }


}

export default Header