import React, { Component } from 'react';

import './music.scss';
import cutter from '../../assets/cutter.jpg';
import spinner from '../../assets/spinner.jpg';
import weaver2 from '../../assets/weaver-2.jpg';
import wakingVessels from '../../assets/waking-vessels.jpg';
import arrow from '../../assets/arrow.svg';
import UnreleasedAlbum from './unreleased';
import dropbox from '../../assets/dropbox.svg';
// import unreleasedAlbum from '../../assets/A_BRILLIANT_LIE--UNRELEASED.zip';

class ReleasedAlbums extends Component {

    constructor(props) {
        super(props)

        this.albums = [
            {
                cover: weaver2,
                title: "Threads: Weaver",
                releaseDate: "March 29th, 2019",
                producedBy: "James Paul Wisner",
                otherBands: "(Paramore, Underoath, Hands Like Houses)",
                tracks: [
                    "The Tarot",
                    "Unwoven",
                    "A Burial",
                    "I Was Never Her",
                    "Tiny Secrets"
                ],
                accent: "#685597"
            },
            {
                cover: spinner,
                title: "Threads: Spinner",
                releaseDate: "August 21th, 2016",
                producedBy: "Jason Aaron Adams",
                otherBands: null,
                tracks: [
                    "Rise & Fall",
                    "The City, The Sound",
                    "This Means War",
                    "You're Not That Broken Darling",
                    "Words for Deaf Ears"
                ],
                accent: "#F5C460"
            }, 
            {
                cover: cutter,
                title: "Threads: Cutter",
                releaseDate: "July 13th, 2015",
                producedBy: "Jason Aaron Adams",
                otherBands: "",
                tracks: [
                    "Always At Odds",
                    "Pieces",
                    "Circles for Sewing",
                    "Drive 'til Morning",
                    "Bittered Bones"
                ],
                accent: "#F84B3C"
            },
            {
                cover: wakingVessels,
                title: "Waking Vessels",
                releaseDate: "October 21th, 2013",
                producedBy: "James Paul Wisner",
                otherBands: "(Paramore, Underoath, Hands Like Houses)",
                tracks: [
                    "Red Eyes (of Thoughts Awry)",
                    "There I Said It",
                    "Astra Castra",
                    "Barren Behind My Lips",
                    "Taller",
                    "Lockets"
                ],
                accent: "#CB6A53"
            },
        ]

        this.state = {
            albumIndex: 0
        }

        this.nextAlbum = this.nextAlbum.bind(this);
        this.previousAlbum = this.previousAlbum.bind(this);
    }

    componentDidMount() {
        this.setUpArrows();
    }

    setUpArrows() {

        let leftArrow = document.querySelector('.left-arrow');
        let rightArrow = document.querySelector('.right-arrow');

    }

    nextAlbum() {
        let albumCount = this.albums.length - 1;

        if (albumCount === this.state.albumIndex) {
            this.setState(state => ({ ...state, albumIndex: 0}));
        } else {
            let newIndex = this.state.albumIndex + 1;
            this.setState(state => ({ ...state, albumIndex: newIndex}));
        }
    }

    previousAlbum() {
        let maxIndex = this.albums.length - 1;

        if (this.state.albumIndex === 0) {
            this.setState(state => ({ ...state, albumIndex: maxIndex}));
        } else {
            let newIndex = this.state.albumIndex - 1;
            this.setState(state => ({ ...state, albumIndex: newIndex}));
        }
    }

    render() {

        return (
            <>
                <h2>ALBUMS</h2>
                <div className="music__wrapper" key="__released">
                    <div className="music__albums">
                        <div className="music__arrow" onClick={this.previousAlbum}>{<img src={arrow} className="left-arrow"/>}</div>
                        <img className="music__album" src={this.albums[this.state.albumIndex].cover} />
                        <div className="music__info">
                            <h3 className="music__album-title">{this.albums[this.state.albumIndex].title}</h3>
                            <div className="music__title-underline" style={{ background: this.albums[this.state.albumIndex].accent }}></div>
                            <p>Produced by: {this.albums[this.state.albumIndex].producedBy}</p>
                            <p className="music__other-bands">{this.albums[this.state.albumIndex].otherBands}</p>
                            <p>Released: {this.albums[this.state.albumIndex].releaseDate}</p>
                            <div className="music__track-label">Tracks:</div>
                            <ul>
                                {
                                    this.albums[this.state.albumIndex].tracks.map((track, index) => {
                                        return <li key={`track-desk_${index + 1}`}>{track}</li>
                                    })
                                }
                            </ul>
                            {
                                this.props.unreleased
                                && this.state.albumIndex === 0
                                && <a className="unreleased__download" href="https://s3.amazonaws.com/abrilliantlie/A_BRILLIANT_LIE--UNRELEASED.zip">Download</a>
                            }
                        </div>
                        <div className="music__arrow" onClick={this.nextAlbum}><img src={arrow} className="right-arrow"/></div>
                    </div>
                    <div className="music__albums-mobile" key="__released-mobile">
                        <div className="music__album-select">
                            <div className="music__arrow" onClick={this.previousAlbum}>{<img src={arrow} className="left-arrow"/>}</div>
                            <img className="music__album" src={this.albums[this.state.albumIndex].cover} />
                            <div className="music__arrow" onClick={this.nextAlbum}><img src={arrow} className="right-arrow"/></div>
                        </div>
                        <div className="music__info">
                            <h3 className="music__album-title">{this.albums[this.state.albumIndex].title}</h3>
                            <div className="music__title-underline" style={{ background: this.albums[this.state.albumIndex].accent }}></div>
                            <p>Produced by: {this.albums[this.state.albumIndex].producedBy}</p>
                            <p className="music__other-bands">{this.albums[this.state.albumIndex].otherBands}</p>
                            <p>Released: {this.albums[this.state.albumIndex].releaseDate}</p>
                            <div className="music__track-label">Tracks:</div>
                            <ul>
                                {
                                    this.albums[this.state.albumIndex].tracks.map((track, index) => {
                                        return <li key={`track-mobile_${index + 1}`}>{track}</li>
                                    })
                                }
                            </ul>
                            {this.props.unreleased
                                &&
                                <a className="unreleased__download" href="https://www.dropbox.com/sh/i4dtz09tlrtp4ed/AACxASqXPAYgTZ-l8NcLrNdCa?dl=0" target="_blank"><img src={dropbox} class="unreleased__dropbox-icon"/>Download</a>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

const Music = (props) => {

    return (
        <div className="music">
            <UnreleasedAlbum { ...props }/>
            <ReleasedAlbums { ...props }/>
        </div>
    )

}

export default Music;