import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './news.scss';

class News extends Component {

    render() {
        return (
            <div className="news">

                <div className="news__wrapper">

                    <h2>NEWS</h2>

                    <div className="news__container">

                        <div className="news__item">
                            <h3 className="news__title">TITLE</h3>
                            <div className="news__divider"></div>
                            <p className="news__body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>

                        <div className="news__item">
                            <h3 className="news__title">TITLE</h3>
                            <div className="news__divider"></div>
                            <p className="news__body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>

                        <div className="news__item">
                            <h3 className="news__title">TITLE</h3>
                            <div className="news__divider"></div>
                            <p className="news__body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default News;