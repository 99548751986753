import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';

import './tour.scss';

class Tour extends Component {

    constructor(props) {
        super(props);

        this.state = { tourData: null };

    }

    componentDidMount() {
        this.fetchTourData();
    }

    fetchTourData() {
        axios.get('https://hl2djplttl.execute-api.us-east-1.amazonaws.com/prod/')
            .then((result) => {
                this.setState({ ...this.state, tourData: result.data })
            })
    }

    formatMonth(index) {
        let months = [
            'JAN',
            'FEB',
            'MAR',
            'APR',
            'MAY',
            'JUN',
            'JUL',
            'AUG',
            'SEP',
            'OCT',
            'NOV',
            'DEC'
        ]

        return months[index];
    }

    render() {
        if (!this.state.tourData) return null;

        let currentYear = (new Date()).getFullYear();

        const datesObject = this.state.tourData.reduce((acc, show) => {
            const date = new Date(show.datetime);
            const year = date.getFullYear();
            
            if (acc[year]) {
                acc[year].push(show);
            } else {
                acc[year] = [show]
            }

            return acc;
        }, {})

        const renderShow = (show) => {

            let date = new Date(show.datetime);

            return (
                <div className="tour__show" key={show.id}>
                    <div className="tour__date">
                        <div className="tour__month">{ this.formatMonth(date.getMonth()) }</div>
                        <div className="tour__divider"> </div>
                        <div className="tour__number">{ date.getDate() }</div>
                    </div>
                    <div className="tour__city">{ show.venue.city }, { show.venue.region }</div>
                    <div className="tour__venue">{ show.venue.name }</div>
                </div>
            )
        }

        return (
            <div className="tour">
                <h2>TOUR</h2>
                <div className="tour__wrapper">
                    {Object.entries(datesObject).reverse().map(([key, dates]) => {

                        if (!Array.isArray(dates)) return null;

                        const splitPoint = Math.floor(dates.length / 2);
                        const firstColumn = dates.splice(0, splitPoint + 1);
                        const secondColumn = dates;

                        return [
                            <div className="tour__year-section">
                                <h3 className="tour__year">{key}</h3>
                                { firstColumn.map(renderShow) }
                            </div>,
                            <div className="tour__year-section tour__year-second">
                                <h3 className="tour__year tour__year-empty"><div className="tour__empty-year">&nbsp;</div></h3>
                                { secondColumn.map(renderShow) }
                            </div>
                        ]
                    })}
                </div>
            </div>
        )
    }

}

export default Tour;