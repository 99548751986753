import React, { Component } from 'react';
import { StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import './media.scss';

import chris from '../../assets/chris.png';
import matt from '../../assets/matt.png';
import tara2 from '../../assets/tara2.png';
import jason from '../../assets/jason.png';
import hob from '../../assets/hob.jpg';
import hob2 from '../../assets/hob2.jpg';
import hob3 from '../../assets/hob3.jpg';
import haven2 from '../../assets/haven2.jpg';

class Media extends Component {

    constructor() {
        super();

        this.images = [
            [
                tara2,
                chris,
                jason,
                matt
            ], [
                hob,
                haven2,
                hob2,
                hob3
            ]
        ]

    }

    renderRow(images) {
        if (!Array.isArray(images)) return;

        let imageRender = images.map((image) => {
            let style = {
                background: `url(${image}) center no-repeat`
            }

            return <div className="media__image" key={image}>
                <div className="media__image-wrap" key={`wrap_${image}`}>
                    <img src={image} key={`img_${image}`} className="img-wide"/>
                </div>
            </div>
        })

        return imageRender;
    }

    renderImage({ node }) {
        const { id, fluid } = node.childImageSharp;

        return (
            <div className="media__image" key={id}>
                <div className="media__image-wrap">
                    <Img fluid={fluid} className="media__gatsby-image"/>
                </div>
            </div>
        )
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query MediaImages {
                        allFile(filter: { relativePath: { in: ["tara2.png", "matt.png", "chris.png", "jason.png", "hob.jpg", "hob2.jpg", "hob3.jpg", "haven2.jpg"] } }) {
                            edges {
                                node {
                                    childImageSharp {
                                        id
                                        fluid(maxWidth: 2880) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
            >
                { data => {
                    console.log(data);
                    return (
                        <div className="media">
                            <div className="media__wrapper">
                                <h2>MEDIA</h2>
                                <div className="media__container">
                                    {data.allFile.edges.map(this.renderImage)}
                                </div>
                                {/* <div className="media__container">
                                    {this.renderRow(this.images[0])}
                                </div>
                                <div className="media__container">
                                    {this.renderRow(this.images[1])}
                                </div> */}
                            </div>
                        </div>
                    )
                }}
            </StaticQuery>
        )
    }

}

export default Media;