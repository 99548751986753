import React, { Component } from 'react';

import './contact.scss';

class Contact extends Component {

    render() {
        return (
            <div className="contact">
                <div className="contact__wrapper">
                    <div className="contact__info">
                        <h2>Contact</h2>

                        <p className="contact__title">General Manager</p>
                        <p className="contact__details">Sarah Raye<br />
                            <a href="mailto:sarah.raye.worldofmusic@gmail.com" className="contact__email-link">sarah.raye.worldofmusic@gmail.com</a><br/>
                            (224) 281-6803
                        </p>

                        <p className="contact__title">Booking</p>
                        <p className="contact__details">
                            <a href="mailto:booking@abrilliantlie.com" className="contact__email-link">booking@abrilliantlie.com</a><br />
                        </p>

                    </div>
                    <div className="contact__info">
                        <h2 className="contact--empty">&nbsp;</h2>

                        <p className="contact__title">Press</p>
                        <p className="contact__details">Meijin Bruttomesso<br />
                        <a href="mailto:meijin@ei-pr.com" className="contact__email-link">meijin@ei-pr.com</a><br/>
                        (212) 777-6727 ext. 209</p>

                        <p className="contact__title">Band</p>
                        <p>
                            Email: <a href="mailto:info@abrilliantlie.com" className="contact__email-link" >info@abrilliantlie.com</a>
                        </p>
                        <p className="contact__details">Phone: (407) 489 7661</p>
                    </div>
                    <div className="contact__info">
                        <h2>Follow Us</h2>
                        <p><a href="https://www.facebook.com/ABrilliantLie/" className="contact__email-link" target="blank">Facebook</a></p>
                        <p><a href="https://www.instagram.com/abrilliantlie/" className="contact__email-link" target="blank">Instagram</a></p>
                        <p><a href="https://twitter.com/abrilliantlie?lang=en" className="contact__email-link" target="blank">Twitter</a></p>
                    </div>
                </div>
            </div>
        )
    }

}

export default Contact;