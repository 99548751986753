import axios from 'axios';

import { GEO_URL } from '../config';

const getGeoLocation = () => {

    return axios.get(GEO_URL)
        .then((response) => {
            if (response.status !== 200) {
                return Promise.reject("Error with request.");
            }
        
            if (response.data.errorMessage) {
                return Promise.reject(response.data.errorMessage);
            }

            if (!response.data) {
                return Promise.reject('No data provided');
            }

            return response.data;
        });

}

export default getGeoLocation;