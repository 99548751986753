import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import strymonLogo from './../../assets/strymon_logo.png';
import prsLogo from './../../assets/prs_logo.png';
import seymourLogo from './../../assets/seymour-logo.png';
import ernieBallLogo from './../../assets/ernie-ball.svg';
import intuneLogo from './../../assets/intune-logo.png';

import './videos.scss';

class Videos extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        // const { unreleased } = this.props;
        // Force 'unreleased' for video while single is out.
        const unreleased = true;

        return (
            <div className="videos" id="videos">
                <div className="videos__container">

                    <div className="videos__sponsors-wrapper">
                        <div className="videos__sponsors">
                            <img src={strymonLogo} className="videos__sponsor" alt="Strymon" />
                            <img src={prsLogo} className="videos__sponsor" alt="PRS Logo" />
                            <img src={seymourLogo} className="videos__sponsor" alt="Seymour Duncan Logo" />
                            <img src={ernieBallLogo} className="videos__sponsor" alt="Ernie Ball Logo" />
                            <img src={intuneLogo} className="videos__sponsor videos__sponsor--no-mobile" alt="Intune Logo" />
                        </div>
                        <div className="videos__sponsors-alt"></div>
                            <img src={intuneLogo} className="videoso__sponsor videos__sponsor-alt" alt="Intune Logo" />
                    </div>

                    <h2 className="videos__title">VIDEOS</h2>
                    <div className="videos__wrapper">
                        <iframe className="videos__main" src="https://www.youtube.com/embed/P7df3tVljkQ" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>

                        <div className="videos__sidebar">
                            <iframe width="352" height="198" className="videos__mobile-only" src="https://www.youtube.com/embed/P7df3tVljkQ" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            <iframe width="352" height="198" src="https://www.youtube.com/embed/ENyF1apZKSk" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            <iframe width="352" height="198" src="https://www.youtube.com/embed/E--XDcuYiQs" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Videos;