import React, { Component } from 'react';

import './listen.scss';
import tara3 from '../../assets/tara3.jpg';

import spotify from '../../assets/icon-spotify-white.svg';
import appleMusic from '../../assets/icon-applemusic-white.svg';
import itunes from '../../assets/icon-itunes.svg';

class Listen extends Component {

    constructor() {
        super()

        this.backgroundStyle = {
            background: `url(${tara3}) no-repeat center`,
            backgroundSize: 'cover'
        }

    }

    render() {
        return (
            <div className="listen" style={this.backgroundStyle}>
                <div className="listen__wrapper">
                    <div className="listen__card">
                        <h2>MUSIC</h2>
                        <div className="listen__about">
                            <div className="listen__player-wrapper">
                                <iframe className="listen__player" src="https://open.spotify.com/embed/user/226omvaweml6dq5yk2nmdwi7q/playlist/5M2tzNIRFN1thpEqKsZtak" width="300" height="450" frameborder="0" allowtransparency="true"></iframe>
                            </div>
                            <h3>Stream now on:</h3>
                            <div className="listen__links">
                                <a href="http://spoti.fi/2oGdLpA" className="listen__link"><img src={spotify} /></a>
                                <a href="https://itun.es/us/1ZIvA" className="listen__link"><img src={appleMusic} /></a>
                                <a href="https://itunes.apple.com/us/artist/a-brilliant-lie/441855221" target="_blank" className="listen__link"><img src={itunes} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Listen;