import React from 'react';
import { StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import strymonLogo from './../../assets/strymon_logo.png';
import prsLogo from './../../assets/prs_logo.png';
import seymourLogo from './../../assets/seymour-logo.png';
import ernieBallLogo from './../../assets/ernie-ball.svg';
import intuneLogo from './../../assets/intune-logo.png';

import logo from './../../assets/ABL-logo.svg';
import spotify from '../../assets/icon-spotify.svg';
import appleMusic from '../../assets/icon-applemusic.svg';
import youtube from '../../assets/icon-youtube.svg';
import itunes from '../../assets/icon-itunes.svg';
import amazon from '../../assets/icon-amazon.svg';
import './links.scss';

const unwovenImage = (
    <StaticQuery
        query={graphql`
            query UnwovenImage {
                file(relativePath: { eq: "unwoven3.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 1080) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
    >
        {data => <Img fluid={data.file.childImageSharp.fluid} className="links__image" alt="A Brilliant Lie." style={{ position: 'absolute' }}/>}
    </StaticQuery>
)

const links = [
    {
        key: 'spotify',
        icon: spotify,
        backgroundColor: '#2FD565',
        url: "https://open.spotify.com/album/5SER8obpomdZ5Pjrq0hc8Y?si=yaeTXSJxQUO5kP4cOl08zw"
    },
    {
        key: 'apple-music',
        icon: appleMusic,
        backgroundColor: '#F7F7F7',
        url: "https://itunes.apple.com/us/album/threads-weaver-ep/1456494050"
    },
    {
        key: 'youtube',
        icon: youtube,
        backgroundColor: '#F40D1A',
        url: 'https://www.youtube.com/watch?v=P7df3tVljkQ'
    },
    // {
    //     key: 'itunes',
    //     icon: itunes,
    //     backgroundColor: '#36C0EE',
    //     url: 'https://itunes.apple.com/us/album/unwoven/1452924517?i=1452924518'
    // },
    // {
    //     key: 'amazon',
    //     icon: amazon,
    //     backgroundColor: '#F48D2A',
    //     url: 'https://www.amazon.com/dp/B07NKX427H/ref=cm_sw_em_r_mt_dp_U_7OjDCbCCG01D0'
    // }
]

const sponsors = [strymonLogo, prsLogo, seymourLogo, ernieBallLogo, intuneLogo];

const UnwovenLinks = (props) => {

    const navigateTo = (url) => {
        if (window && window.location) {
            // eslint-disable-next-line
            if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
                // eslint-disable-next-line
                fbq('track', 'Lead');
            }
            window.location.href = url
        }
    }

    return (
        <div className="links">
            <div className="links__wrapper">
                <div className="links__options">
                    <img src={logo} className="links__logo" alt="A Brilliant Lie Logo"/>
                    <h3 style={{ fontWeight: 'normal', fontSize: '1rem' }}>STREAM 'UNWOVEN':</h3>
                    {links.map((link, index) => {
                        return (
                            <div 
                                key={link.key} 
                                className="links__button" 
                                onClick={() => navigateTo(link.url)}
                                style={{ background: link.backgroundColor }}
                            >
                                <img src={link.icon} className="links__icon"/>
                            </div>
                        )
                    })}
                </div>
                <div className="links__sponsors">
                    {sponsors.map((src, index) => {
                        return <img src={src} key={`sponsor__${index}`} alt="Sponsor" className="links__sponsor-logo" />
                    })}
                </div>
                {unwovenImage}
            </div>
        </div>
    )

}

export default UnwovenLinks;