import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './band.scss';
import haven from '../../assets/haven.jpg';

class Band extends Component {

    constructor() {
        super()

        this.backgroundStyle = {
            background: `url(${haven}) no-repeat center`,
            backgroundSize: 'cover'
        }

    }

    render() {
        return (
            <div className="band" style={this.backgroundStyle}>
                <div className="band__wrapper">
                    <div className="band__card">
                        <h2>ABOUT</h2>
                        <div className="band__about">
                            <p>A BRILLIANT LIE is an explosively-energetic alternative rock band from Orlando, FL.</p>
                            <p>Alongside relentlessly touring the US in support of their current record cycle, the band are working alongside reputable producer, James Paul Wisner (Paramore, Underoath, Hands like Houses) writing and recording their new material.</p>
                            <p>Having received airtime on many of the Southeast’s largest Clear Channel and college radio stations, they’re no strangers to the acclamation of the mainstream. Performing alongside the likes of Anberlin, Minus the Bear, Passion Pit and other renowned acts has propelled them to headline performances at the world famous House Of Blues, State Theatre, Freebird Live and numerous sought after music festivals including Sunfest, Florida Music Festival and Rocklahoma.</p>
                            <p>If they aren’t touring in a smelly van, they are probably out giving high fives. Catch A BRILLIANT LIE this year. The hype is REAL.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Band;