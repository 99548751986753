import React, { Component } from 'react';

import './unreleased.css';
import dropbox from '../../assets/dropbox.svg';
import arrow from '../../assets/arrow.svg';
import weaver2 from '../../assets/weaver-2.jpg';

// import unreleasedAlbum from '../../assets/A_BRILLIANT_LIE--UNRELEASED.zip';

const album = {
    cover: weaver2,
    title: "Threads: Weaver",
    releaseDate: "The Future...",
    producedBy: "James Paul Wisner",
    otherBands: "(Paramore, Underoath, Hands Like Houses)",
    tracks: [
        "The Tarot",
        "Unwoven",
        "A Burial",
        "I Was Never Her",
        "Tiny Secrets"
    ],
    accent: "#43BCE8"
}

const UnreleasedAlbum = ({ unreleased }) => {

    return null;

    let styles = unreleased ? {} : { display: 'none' };

    return (
        <>
            <h2 style={styles}>NEW MUSIC</h2>
            <div style={styles} className="unreleased__wrapper unreleased__unreleased" key="__unreleased">
                <div className="unreleased__albums">
                    <div className="unreleased__arrow" style={{ opacity: 0 }}>{<img src={arrow} className="left-arrow"/>}</div>
                    <img className="unreleased__album" src={album.cover} />
                    <div className="unreleased__info">
                        <h3 className="unreleased__album-title">{album.title}</h3>
                        <div className="unreleased__title-underline" style={{ background: album.accent }}></div>
                        <p>Produced by: {album.producedBy}</p>
                        <p className="unreleased__other-bands">{album.otherBands}</p>
                        <p>Release Date: {album.releaseDate}</p>
                        <div className="unreleased__track-label">Tracks:</div>
                        <ul>
                            {
                                album.tracks.map((track, index) => {
                                    return <li key={`track-unrelased_${index + 1}`}>{track}</li>
                                })
                            }
                        </ul>
                        <a className="unreleased__download" href="https://s3.amazonaws.com/abrilliantlie/A_BRILLIANT_LIE--UNRELEASED.zip">Download</a>
                    </div>
                    <div className="unreleased__arrow" style={{ opacity: 0 }}><img src={arrow} className="right-arrow"/></div>
                </div>
                <div className="unreleased__albums-mobile" key="__unreleased-mobile">
                    <div className="unreleased__album-select">
                        <div className="unreleased__arrow" style={{ opacity: 0 }}>{<img src={arrow} className="left-arrow"/>}</div>
                        <img className="unreleased__album" src={album.cover} />
                        <div className="unreleased__arrow" style={{ opacity: 0 }}><img src={arrow} className="right-arrow"/></div>
                    </div>
                    <div className="unreleased__info">
                        <h3 className="unreleased__album-title">{album.title}</h3>
                        <div className="unreleased__title-underline" style={{ background: album.accent }}></div>
                        <p>Produced by: {album.producedBy}</p>
                        <p className="unreleased__other-bands">{album.otherBands}</p>
                        <p>Released: {album.releaseDate}</p>
                        <div className="unreleased__track-label">Tracks:</div>
                        <ul>
                            {
                                album.tracks.map((track, index) => {
                                    return <li key={`track-unreleased-mobile_${index + 1}`}>{track}</li>
                                })
                            }
                        </ul>
                        <a className="unreleased__download" href="https://www.dropbox.com/sh/i4dtz09tlrtp4ed/AACxASqXPAYgTZ-l8NcLrNdCa?dl=0" target="_blank"><img src={dropbox} class="unreleased__dropbox-icon"/>Download</a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default UnreleasedAlbum;